:root{
  --green:#4e9c4b;
  --yellow: #ffb800;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.flex-i{
  display: flex;
  align-items: center;
}
.flex-s{
  display: flex;
  align-items: flex-start;
}
.flex-a{
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-initial{
  text-align: left !important;
}

.custom-list {
  /* padding-left: 20px;  */
  list-style-type: disc !important; 
}

.custom-list li {
  list-style-position: outside; 
  margin-left: 30px; 
  text-align: justify; 
}
/* styles for credit rebuilder page */
.green{
  color: #4e9c4b;
}
.yellow{
  color: var(--yellow);
}
.container__small{
  max-width: 1000px !important;
}
.crbldrSec1{
}
.space_for_website_top{
  padding-top: 150px;
}
.crbldrSec1__heading{
  text-align: center;
  font-size: 43px;
  font-weight: bold;
  margin-bottom: 20px;
}
.crbldrSec1__para{
  font-size: 22px;
  color: #333333;
  margin-bottom: 40px;
  max-width: 800px;
  margin-inline: auto;
  text-align: center;
}
.crbldrSec1__heading2{
  color: #222;
  font-size: 22px;
  font-style: italic;
  font-weight: 600;
  margin-bottom: 50px;
  text-align: center;
}

.crbldrSec1__col1{
  width: 60%;
}
.crbldrSec1__col2{
  width: 40%;
}
.crbldrSec1__listBox__heading{
  background-color: var(--green);
  font-size: 20px;
  color: #fff;
  padding: 20px 10px;
  font-weight: 600;
  margin-bottom: 0;
}
.crbldrSec1__listBoxList{
  text-align: left;
  background-color: rgba(40,41,54,1);
  color: #e7e7e7;
  padding: 35px 22px 80px 26px ;
  font-size: 17px;
  margin: 0;
  position: relative;
}
.crbldrSec1__listBoxList__span{
  margin-bottom: 20px;
  display: block;
}
.crbldrSec1__listBoxList__span--2{
  margin-top: 20px;
}
.crbldrSec1__listBoxList__item{
  position: relative;
  padding-left: 15px;
}
.crbldrSec1__listBoxList__item::before{
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #e7e7e7;
  position: absolute;
  top: 8px;
  left: 0;
}
.rbldrSec1__listBoxList__linkBtn{
  position: absolute;
  bottom: -28px;
  left: 50%;
  transform: translateX(-50%);
  top: auto;
  background-color: var(--yellow);
  width: 80%;
  color: #000;
  /* border-radius: 5px; */
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 15px 10px;
}
.rbldrSec1__listBoxList__linkBtn:hover{
  background-color: #f2a500;
}
.crbldrSec1__col1__desc{
  font-size: 17px;
  color: #777;
  margin-bottom: 20px;
}
.couterNumber{
  width: 38px;
  height: 38px;
  border: 1px solid #ccc;
  border-radius: 50%;
  color: #ccc;
}
.couterNumberBox{
  width: 90%;
}
.couterNumberBox__para{
  width: calc(100% - 60px);
  font-size: 21px;
  color: #222;
  font-weight: 400;
}
.couterNumberBox{
  justify-content: space-between;
}
.couterNumberBoxHolder{
  transform: translateY(-200px);
}
.questionCallBox{
  border-radius: 100px;
  width: 80%;
  background: rgba(199, 220, 128, 1);
  border: 1px solid  #ccc;
  margin-top: 46px;
  text-align: center;
  padding: 10px 20px;
  margin-inline: auto;
  margin-bottom: 23px;

}
.questionCallBox__heading{
  font-size: 20px;
  color: #222;
  font-weight: 600;
}
.questionCallBox__btn{
  background-color: var(--green);
  width: 90%;
  margin-inline: auto;
  border-radius: 30px;
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  padding: 5px;
}
.pricingbox{
  text-align: center;
  color: #222222;
}
.pricingbox__heading{
  font-size: 22px;
  font-weight: 700;
}
.pricingbox__subHeading{
  font-size: 16px;
  font-weight: 700;
}
.pricingbox__subHeading2{
  font-size: 14px; 
  font-weight: 700;
}

.credit-comparison {
  display: flex;
  justify-content: center;
  gap: 45px;
  transform: translateY(-100px);
}

.credit-comparison__column {
  flex: 1;
  background-color: #f5f5f5;
  border-radius: 20px;
  position: relative;
  padding-bottom: 110px;
}

.credit-comparison__header {
  padding: 16px 19px 32px 20px;
  color: white;
  text-align: center;
  clip-path: polygon(0 0, 100% 0, 100% 70%, 50% 100%, 0 70%);
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px 20px 0 0;
}

.credit-comparison__header--green {
  background-color: #4CAF50;
}

.credit-comparison__header--yellow {
  background-color: #FFC107;
}

.credit-comparison__title {
  font-size: 26px;
  font-weight: 600;
  margin: 0;
  max-width: 250px;
}

.credit-comparison__content {
}

.credit-comparison__text {
  margin: 0;
  color: #0c1922;
  border-bottom: 1px dashed #26323859;
  padding: 20px;
  font-size: 14px;
}

.credit-comparison__text--link {
  color: #2196F3;
  text-decoration: underline;
}
.credit-comparison__text--link:hover{
  color: #2196F3;
}
.credit-comparison__cross-icon {
  width: 21px;
  height: 21px;
  display: block;
}

.credit-comparison__link {
  color: #2196F3;
  text-decoration: none;
  display: block;
  margin: 1rem 0;
}

.credit-comparison__link:hover {
  text-decoration: underline;
}

.credit-comparison__button {
  width: 70%;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.2s;
  position: absolute;
  padding: 12px 15px;
  min-height: 64px;
  font-size: 16px;
  line-height: 1.2;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
  text-align: center;
}

.credit-comparison__button:hover {
  opacity: 0.9;
}

.credit-comparison__button--green {
  background-color: var(--green);
}

.credit-comparison__button--yellow {
  background-color: var(--yellow);
}
.secDevider{
  padding: 30px 0;
  background-color: var(--green);
}
.secDevider__heading{
  font-size: 34px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}
.featuresSec{
  padding: 70px 0 65px 0;
}
.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px 20px;
}

.feature {
}

.feature__icon {
  margin-bottom: 20px;
  border-radius: 10px;
}
.feature__iconimg{
  border-radius: 10px;
}
.feature__title {
  font-size: 22px;
  margin-bottom: 10px;
  color: var(--green);
  font-weight: bold;
}

.feature__description {
  font-size: 14px;
  color: #777;
  margin-bottom: 0;
}
.cta {
  margin-top: 60px;
  text-align: center;
}

.cta__button {
  background-color: var(--yellow);
  width: 100%;
  max-width: 293px;
  color: #000;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 15px 10px;
  border: none;
  border-radius: 5px;
}
.cta__button:hover{
  background-color: #f2a500;
}


/* CommentSection.css */
.comment-section{
  padding: 50px 0;
}
.commentCard_wrapper {
  display: flex;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  gap: 20px 2%;
  flex-wrap: wrap;
  
}

.comment-card {
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 24px;
  width: 32%;
}

.comment-image {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-right: 15px;
}

.comment-name {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0;
}

.comment-location {
  color: #666;
  margin-bottom: 0;
  font-size: 12px;
}

.comment-text {
  line-height: 1.4;
  font-size: 15px;
  color: #777;
}
.comment-card--half{
  width: 49%;
}

.faq {
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

.faq__item{
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
  padding: 15px 0;
  margin-bottom: 0;
}
.faq__icon{
  font-size: 26px;
  font-weight: bold;
  transition: transform 0.3s ease;
}
.faq__question {
  cursor: pointer;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 18px;
  color: #222;
  font-weight: bold;
}
.faq__answer {
  font-size: 16px;
  color: #333333;
  line-height: 1.6;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
  margin-bottom: 10px;
}

.faq__item--active .faq__answer {
  max-height: 500px;
  opacity: 1;
  padding: 0px 15px;
}
.faq__question:hover,
.faq__item--active .faq__question {
  color: var(--yellow);
}
.faqSec{
  padding: 45px 0;
}
.faq__item:nth-last-child(1){
  border-bottom: none;
}
.faq__answer:nth-last-child(1){
  margin-bottom: 0;
}
.about7steps{
  padding: 45px 0 0 0 ;
}
.about7steps__text{
  text-align: center;
  display: block;
  width: 100%;
  max-width: 800px;
  margin-inline: auto;
  line-height: 1.5;
  font-size: 21px;
  color: rgb(51, 51, 51);
}
.about7steps__text__link{
  text-decoration: underline;
}
.about7steps__text__link:hover{
  color: rgb(51, 51, 51);
}
.cta__button--about7steps{
  width: 100%;
  max-width: 480px;
  font-size: 15px;
}
.cta--about7steps{
  margin-top: 30px;
}
@media(max-width:1023px){
  .crbldrSec1__heading{
    font-size: 34px;
    margin-bottom: 10px;
  }
  .crbldrSec1__para {
    font-size: 19px;
    margin-bottom: 30px;
    max-width: 600px;
  }
  .crbldrSec1__heading2{
    font-size: 19px;
  }
  .crbldrSec1__listBox__heading {
    font-size: 18px;
  }
  .crbldrSec1__listBoxList {
    padding: 25px 20px 80px 22px;
    font-size: 15px;
  }
  .crbldrSec1__col1__desc {
    font-size: 16px;
  }
  .couterNumberBox__para {
    width: calc(100% - 50px);
    font-size: 19px;
  }
  .questionCallBox__heading {
    font-size: 17px;
  }
  .questionCallBox__btn{
    font-size: 18px;
  }
  .credit-comparison{
    column-gap: 10px;
  }
  .credit-comparison__title {
    font-size: 21px;
    max-width: 200px;
  }
  .container__small{
    max-width: 760px !important;
  }
  .credit-comparison__text{
    padding: 17px 15px;
  }
  .credit-comparison__text{
    font-size: 11px;
  }
  .credit-comparison__cross-icon {
    width: 16.5px;
    height: 16.6px;
  }
  .secDevider__heading{
    font-size: 22px;
  }
  .secDevider {
    padding: 20px 0;
  }
  .comment-image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .comment-text{
    font-size: 14px;
  }
  .comment-card{
    padding: 15px;
  }
  .about7steps__text {
    max-width: 630px;
    font-size: 17px;
  }
  .features {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px 15px;
  }
  .feature__title {
    font-size: 18px;
  }
}
@media(max-width:600px){
  .container__small{
    max-width: 350px !important;
  }
  .space_for_website_top {
    padding-top: 120px;
  }
  .crbldrSec1__heading {
    font-size: 25px;
    margin-bottom: 30px;
    max-width: 230px;
    margin-inline: auto;
    line-height: 1.2;
  }
  .crbldrSec1__para{
    font-size: 16px;
    line-height: 1.2;
  }
  .crbldrSec1__heading2 {
    font-size: 14px;
  }
  .crbldrSec1__wrapper{
    flex-wrap: wrap;
    margin-bottom: 60px;
  }
  .crbldrSec1__col1,.crbldrSec1__col2{
    width: 100%;
  }
  .couterNumberBoxHolder{
    transform: translate(0);
  }
  .couterNumber__wrapper{
    flex-wrap: wrap;
  }
  .couterNumberBox{
    width: 100%;
  }
  .couterNumberBox__para {
    font-size: 16px;
  }
  .questionCallBox{
    margin-top: 0;
  }
  .credit-comparison{
    flex-wrap: wrap;
    transform: translate(0);
    row-gap: 60px;
  }
  .crbldrSec1{
    margin-bottom: 35px;
  }
  .credit-comparison__column{
    flex: 1 1 100%;
    padding-bottom: 75px;
  }
  .credit-comparison__text {
    font-size: 13px;
  }
  .credit-comparison__text br{
    display: none;
  }
  .credit-comparison__button{
    width: 85%;
    font-size: 14px;
    padding-inline: 25px;
  }
  .credit-comparison__title {
    font-size: 19px;
  }
  .credit-comparison-sec{
    padding-bottom: 60px;
  }
  .secDevider__heading {
    font-size: 19px;
  }
  .features {
    grid-template-columns: repeat(1, 1fr);
    gap: 35px 15px;
  }
  .comment-card{
    width: 100%;
  }
  .faq {
      padding: 11px;
  }
  .faq__question {
    padding: 0px 10px;
    font-size: 15px;
  }
  .faq__answer{
    font-size: 14px;
  }
  .faq__item--active .faq__answer {
    padding: 0px 10px;
  }
  .cta__button{
    font-size: 16px;
  }
  .cta--about7steps{
    font-size: 13px;
  }
} 
/* Add more styles as needed */
/* styles end for credit rebuilder page */